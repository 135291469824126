// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Notification from './firebaseNotifications/Notification'

// ----------------------------------------------------------------------

export default function App() {
  return (
    <>
      <ThemeProvider>
        <ThemeColorPresets>
          <ThemeLocalization>
            <RtlLayout>
              <NotistackProvider>
                <ProgressBarStyle />
                {/* <Notification /> */}
                {/* <Settings /> */}
                <ScrollToTop />
                <Router />
              </NotistackProvider>
            </RtlLayout>
          </ThemeLocalization>
        </ThemeColorPresets>
      </ThemeProvider></>
  );
}
