import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const currentUser = JSON.parse(localStorage.getItem('accessToken'));

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'Authorization': `Bearer ${currentUser?.access_token}`,
    'x-user': currentUser?.id
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
