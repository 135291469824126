import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

var firebaseConfig = {
    apiKey: "AIzaSyCwsNf9vVLPp7D43W_VmfYlZMUOfTn-lBE",
    authDomain: "vmrf-intranet.firebaseapp.com",
    projectId: "vmrf-intranet",
    storageBucket: "vmrf-intranet.appspot.com",
    messagingSenderId: "114346426906",
    appId: "1:114346426906:web:a2807ff50c608a247687a3",
    measurementId: "G-PE3GDKKN29"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: `BDQjLZ3AMiUDDuYEuXns9TceoOOTxOXhRb4s0fURpk0-luKu6PuiLWOl4uNgZPeml3OAVnTl_hFVS9kqFNWJ0wQ` })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

  