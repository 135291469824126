import { Suspense, lazy } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import MicroFrontend from '../MicroFrontend';
// guards
import GuestGuard from '../guards/GuestGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

if (process.env.NODE_ENV === "production") {
  var {
    REACT_APP_DASHBOARD_HOST_prod: dashboard,
    REACT_APP_ETICKET_HOST_prod: eticket,
    REACT_APP_SADASHBOARD_HOST_prod: admin,
    REACT_APP_ADMINISTRATOR_HOST_prod: administrator,
    REACT_APP_Angular_HOST_prod: angular
  } = process.env;
} else {
  var {
    REACT_APP_DASHBOARD_HOST: dashboard,
    REACT_APP_ETICKET_HOST: eticket,
    REACT_APP_SADASHBOARD_HOST: admin,
    REACT_APP_ADMINISTRATOR_HOST: administrator,
    REACT_APP_Angular_HOST: angular
  } = process.env;
}


const Angular = ({ history }) => (
  <MicroFrontend history={history} host={angular} name="angular" />
);

const Eticket = ({ history }) => (
  <MicroFrontend history={history} host={eticket} name="eticket" />
);

const Dashboard = ({ history }) => (
  <MicroFrontend history={history} host={dashboard} name="dashboard" />
);

const Admin = ({ history }) => (
  <MicroFrontend history={history} host={admin} name="admin" />
);

const Administrator = ({ history }) => (
  <MicroFrontend history={history} host={administrator} name="administrator" />
);

export default function Router() {

  return (
    <Routes>

      <Route
        path='/'
        render={() =>
          <GuestGuard>
            <Login />
          </GuestGuard>}
        element={
          <GuestGuard>
            <Login />
          </GuestGuard>}
      />
      <Route path="/reset-password" render={() => <ResetPassword />} element={<ResetPassword />} />
      <Route path='/dashboard' render={() => <Dashboard />} element={<Dashboard />} />
      <Route path="/admin" render={() => <Admin />} element={<Admin />} />
      <Route path="*" render={() => <Login />} element={<Login />} />
    </Routes>
  );
}

const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));