import PropTypes from 'prop-types';
import { Navigate} from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

if(process.env.NODE_ENV === "production"){
  var {
    REACT_APP_DASHBOARD_HOST_prod: dashboard,
  } = process.env;
}else{
  var {
    REACT_APP_DASHBOARD_HOST: dashboard,
  } = process.env;
}

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    window.location.replace(dashboard);
    return (
      <Navigate to={PATH_DASHBOARD.root} />
    )
  }

  return <>{children}</>;
}
